import { Link } from "react-router-dom";

// Framer Motion – motion
import { motion } from "framer-motion";
import { preloader, page_transition } from "../components/Animations";

// Meta Data (React Hemlet)
import GetMetaData from "../components/GetMetaData";
import pageNotFoundMeta from "../metaData/pageNotFoundMeta.json";
import metaImg_404 from "../images/meta-images/gloryforkorn--og--404.jpg";

const NotFound = () => {
  return (
    <>
      <GetMetaData
        ogSiteName={pageNotFoundMeta.ogSiteName}
        title={pageNotFoundMeta.title}
        ogTitle={pageNotFoundMeta.ogTitle}
        twitterTitle={pageNotFoundMeta.twitterTitle}
        description={pageNotFoundMeta.description}
        ogDescription={pageNotFoundMeta.ogDescription}
        twitterDescription={pageNotFoundMeta.twitterDescription}
        imageUrl={metaImg_404}
        canonical={pageNotFoundMeta.canonical}
      />

      <motion.div className="preloader" initial="preloader_initial" animate="preloader_animation" exit="preloader_exit" variants={preloader}></motion.div>
      <motion.div
        className="page-transition"
        initial="page_transition_initial"
        animate="page_transition_animate"
        exit="page_transition_exit"
        variants={page_transition}
      >
        <div id="pageNotFound">
          <div className="page-not-found">
            <h1 className="glitch" data-title="404">
              404
            </h1>
            <p>No content found... LEAVE NOW!</p>
            <Link className="btn btn--1 btn--a mt--200" to="/">
              Back
            </Link>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default NotFound;
