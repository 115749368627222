import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { imprintPath, privacyPath } from "./routes";


// varriables
let current_path;


// custom hook to get the current pathname in React
const usePathname = () => {
  const location = useLocation();
  current_path = location.pathname;
  console.log("current_path: " + current_path);
  return current_path;
}


// functions
function SetConditionalContent1() {
    return <p>Welcome back!</p>;
}

function SetConditionalContent2() {
    return <p>Welcome asdas!</p>;
}

function GetFooterMenu() {
    return "if condition is met, show me";
}

function GetConditionalContent() {   
    if(current_path === imprintPath) {
        return <GetFooterMenu />;
    } else if(current_path === privacyPath) {
        return <GetFooterMenu />;
    } else {
        return '';
    }
}



const Footer = () => {

    usePathname();

    return (
    <>
        <footer>
            <div className="container">

            <GetConditionalContent/>

            <div className='footer-content'>
                <div className='legal-info'>
                    <p>Copyright &copy; Glory For Korn {new Date().getFullYear()} | Design by <a href="https://pixellab.graphics/" target="_blank" rel="noopener">Pixellab</a></p>
                </div>

                <div className='legal-links'>
                    {/*
                    <Link to="/imprint">Imprint</Link>
                    <span className="separator">|</span>
                    */}
                    <Link to="/privacy">Privacy</Link>
                </div>
            </div>

            </div>
        </footer>
    </>
    )
}

export default Footer
