// https://www.framer.com/docs/introduction/
// https://www.youtube.com/watch?v=qJt-FtzJ5fo
// https://www.youtube.com/watch?v=YxLMAk2H3ns&t=477s



// preloader für "projects" muss noch anders gemacht werden, weil wir sind den preloader mit der komponente auf der startseite 2x einbinden und das wollen wir nicht

// watch videos, so we don't have the page transition on page load. watch how to remove it -> done
// disable animations for mobile? because we have a drop down menu as an overlay for the transitions?
export const preloader = {
    preloader_initial: {
        x: "0%"
    }, 
    preloader_exit: {
        x: "100%",
        transition: {
            duration: .3
        }
    },
}

export const page_transition = {
    page_transition_initial: {
        opacity: 0,
    }, 
    page_transition_animate: {
        opacity: 1,
        transition: {
            duration: .5,
            ease: "easeInOut"
        }
    }, 
    page_transition_exit: {
        opacity: 1
    },
}