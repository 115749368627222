import "./App.scss";
// Router ist zwar ausgegraut, aber wenn wir ihn entfernen, rendert die Seite nicht mehr korrekt.
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { homePath, imprintPath, privacyPath } from "./components/routes";

import Home from "./pages/Home--page";
import Imprint from "./pages/Imprint--page";
import Privacy from "./pages/Privacy--page";
import NotFound from "./pages/NotFound--page";

import Footer from "./components/Footer";

// Framer Motion – AnimatePresence
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();
  return (
    <>
      <AnimatePresence exitBeforeEnter initial={true}>
        {/* first we need to finish the animation, so the "scroll to top" doesn't look janky. We also want to enter every page scrolled to top. */}
        <ScrollToTop />

        <Switch location={location} key={location.pathname}>
          <Route exact path={homePath} component={Home} />
          <Route exact path={imprintPath} component={Imprint} />
          <Route exact path={privacyPath} component={Privacy} />

          {/* This needs to be the last component, so if no Route matches, we render the 404 page */}
          <Route component={NotFound} />
        </Switch>
      </AnimatePresence>

      <Footer />
    </>
  );
}

export default App;
