import React from "react";
import { Helmet } from "react-helmet";

// const GetMetaData = (props) => {
// {props.title}
// ...
// const GetMetaData = ({ title, description }) => {
// {title}
// ...

import websiteUrl from "../metaData/websiteUrl.json";

const GetMetaData = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />

      <link rel="canonical" href={props.canonical} />

      <meta property="og:locale" content="en_EN" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={websiteUrl.hostname + window.location.pathname + window.location.search} />
      <meta property="og:image" content={websiteUrl.hostname + props.imageUrl} />
      <meta property="og:title" content={props.ogTitle} />
      <meta property="og:description" content={props.ogDescription} />
      <meta property="og:site_name" content={props.ogSiteName} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={props.twitterTitle} />
      <meta name="twitter:description" content={props.twitterDescription} />
    </Helmet>
  );
};

export default GetMetaData;